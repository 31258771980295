<template>
  <div>

    <v-card
      flat
      color="primary"
      dark
    >
      <v-card-title>
        Current Weight Readings
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col
              cols='12'
              xs='12'
              md='12'
            >
              <v-data-table
                class="transparent"
                :items="instruments"
                :headers="[
                    {text : 'Weighbridge', value : 'name'},
                    {text : 'Weight', value : 'lastReading'},
                    {text : 'Last Refreshed', value : 'datetime'},
                    {text : '', value : 'resetAction'}
                  ]"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:item.resetAction="{item}">
                  <v-btn
                    @click="setZero(item)"
                    class='orange'
                  >Set Zero</v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import SelectFunctions from "@/select/SelectFunctions";
import dayjs from "dayjs";

export default {

  mounted ()
  {
    this.instruments = JSON.parse(process.env.VUE_APP_WEIGHBRIDGE_INSTRUMENTS);

    this.instruments = this.instruments.map(record =>
    {
      record.lastReading = 0.00
      record.datetime = null; // the last reading date / time
      return record;
    })

    this.getWeightReadings();
    this.timerID = setInterval(() => { this.getWeightReadings() }, 5000);

    this.getUsername();

  },

  beforeDestroy ()
  {
    clearInterval(this.timerID);
  },

  methods:
  {

    async getUsername ()
    {
      this.username = await SelectFunctions.getUsername();

    },

    async getWeightReadings ()
    {
      for (let record of this.instruments)
      {
        try
        {
          let response = await fetch(
            record.address
            + `/LAST_READING`,
            {
              method: 'GET'
            }
          );

          if (!(response && response.ok)) { throw "Invalid return from server : " + response.status }

          let result = await response.json()

          console.log("getWeightReadings : result :", result);

          // update the record with the weight readings:
          try
          {
            if (result.lastReading === "") { throw "Invalid reading returned " }
            record.lastReading = Number(result.lastReading).toString().concat(" KG");
          }
          catch (e)
          {
            record.lastReading = "Unknown";
            console.error("getWeightReadings : caught error in weight number conversion :  ", e)
          }
          record.datetime = dayjs();

          this.$forceUpdate();

        }
        catch (e)
        {
          console.error("getWeightReadings : caught error : ", e);
        }

      }
    },

    async setZero (item)
    {
      if (!await this.$root.$confirm(
        `Are you sure that you wish to reset the ${item.code} instrument?`,
        {
          title: 'Set Zero?',
          color: 'orange'
        }
      )) { return false; }

      // okay we want to reset the reading for this instrument:

      try
      {

        // record the log entry
        SelectFunctions.performPut(
          process.env.VUE_APP_BACKEND_ENDPOINT
          + `systemlog`,
          {
            user: this.username,
            process: 'ISSUED RESET_ZERO COMMAND',
            info:
              `Reset zero command was issued to instrument ${item.code} / ${item.address}`
          }
        );

        // issue the reset command
        let response = await fetch(
          item.address
          + `/RESET_ZERO`,
          {
            method: 'GET'
          }
        );

        this.$notify({
          group: 'global',
          type: 'success',
          title: 'Reset Command Issued',
          text: 'The reset command was sent successfully.'
        });

        this.getWeightReadings();

      }
      catch (e)
      {
        this.$notify({
          group: 'global',
          type: 'error',
          title: 'Problem With Reset',
          text: 'There was a problem sending the reset command, please re-try'
        });
      }


    },
  },

  data: () =>
  {
    return {
      instruments: [],
      timerID: null,
      username: '',
    }
  }
}
</script>