var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":"","color":"primary","dark":""}},[_c('v-card-title',[_vm._v(" Current Weight Readings ")]),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"12"}},[_c('v-data-table',{staticClass:"transparent",attrs:{"items":_vm.instruments,"headers":[
                  {text : 'Weighbridge', value : 'name'},
                  {text : 'Weight', value : 'lastReading'},
                  {text : 'Last Refreshed', value : 'datetime'},
                  {text : '', value : 'resetAction'}
                ],"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.resetAction",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"orange",on:{"click":function($event){return _vm.setZero(item)}}},[_vm._v("Set Zero")])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }