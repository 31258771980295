<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-card-title>
          Reset Bridge Weight Readings
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols='12'
              xs='12'
              md='12'
            >
              <p>
                This form allows you to reset the weighbridge weight readings.<br />
                This sends a 'set zero' command to the weighbridge to instruct it that the weight it is
                currently reading should be the zero weight.<br />
                Only issue this command if the weighbridge is clear and nothing is currently on the scales.
              </p>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
      <v-row class="mt-4">
        <v-col
          cols='12'
          xs='12'
          md='12'
        >
          <reset-readings />

        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>

import SelectFunctions from "@/select/SelectFunctions";
import ResetReadings from "@/components/ResetReadings";


export default {
  components:
  {
    ResetReadings,
  }

}
</script>